import React,{ useEffect, useState, useRef } from "react";
import { Alert, Accordion } from "react-bootstrap";
import customParseFormat from "dayjs/plugin/customParseFormat";
import PopModal from "../components/ui/PopUpModal";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Layout from "../components/ui/Layout";
import styles from "./Home.module.scss";
import HeaderProgress from "./HeaderProgress";
import { ReactComponent as TaskRightIcon } from "../assets/images/caret-icon-right-orange.svg";
import { TASK_STATUS, TASK_TYPE } from "../utils/constants";
import Loader from "../components/ui/Loader";
import { useDimensions } from "../components/ui/use-dimensions";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/home/actions";
import * as BGActions from "./state/background/actions";
import * as LoginActions from "./state/login/actions";
import { dayMonthYearFormat, monthDayYearFormat } from "../utils";
import { Endpoints } from "../core/networking";
import { Scrollbars } from "react-custom-scrollbars-2";
import { createPortal } from "react-dom";
import { debounce, isEmpty } from "lodash";
import { APPLICANT_REJECTED_STATUS } from "../utils/constants";
import { Logout } from '../services/Common';
import { useCookies } from 'react-cookie';
import BackgroundFetch from "../pages/components/BackgroundFetch";

dayjs.extend(customParseFormat);

function Home() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        loginData: { loginUser },
        homeData: { submittedTasks, approveTasks, progressData, iframeTaskStatus, getNotificationInfo },
        bgData: { tasks, notifications },
    } = useSelector((state) => state);
    const { response: submitedTask, isLoading: loading } = submittedTasks || {};
    const { isLoading: appovalLoading } = approveTasks || {};
    const { response: loginVal, isLoading: loginLoading } = loginUser || {};
    const { response: progressBarData } = progressData || {};
    const { response: iframeTask, isLoading: iframeTasksLoading } = iframeTaskStatus || {};
    const { response: notificationInfo } = getNotificationInfo || {};
    const { response: taskList } = tasks || {};
    const { response: notificationsList } = notifications || {};

    const [, setCookie, removeCookie] = useCookies(null);
    const [donorTasks, setDonorTasks] = useState(null);
    const [expandedItem, setExpandedItem] = useState(["0"]);
    const containerRef = useRef(null);
    const { height } = useDimensions(containerRef);
    const [scrollbarHeight, setScrollBarHeight] = useState("");
    const listRef = useRef();
    const [showModel, setShowModel] = useState(false);
    const [modelInfo, setModelInfo] = useState([]);
    const [taskId, setTaskId] = useState(null);
    const [notificationContent,setNotificationContent] = useState([]);
    const [domReady, setDomReady] = React.useState(false)
    const progressInfo = useRef();

    useEffect(() => {
        document.title = "Home";
        setDomReady(true);
        setDonorTasks(null);
        dispatch(BGActions.taskFetchClear());
        dispatch(Actions.taskFetchClear());
        dispatch(Actions.sendToApproveClear());
        dispatch(Actions.progressBarClearData());
        dispatch(Actions.notificationAcknowledgeClear());

        let applicantID = loginVal?.data?.applicant_id;

        if (applicantID !== "" && applicantID !== undefined && applicantID !== null) {
            const taskEndPoint = Endpoints.GET_DONOR_TASK + applicantID;
            let params = {
                endpoint: taskEndPoint,
                method: "GET"
            };
            dispatch(Actions.taskFetchRequest(params));
            dispatch(Actions.sendToApproveRequest(params));

            const progressEndPoint = Endpoints.PROGRESS_BAR_DATA + applicantID //"163354"
            let prg_param = {
                endpoint: progressEndPoint,
                method: "GET",
            };
            dispatch(Actions.progressBarFetchData(prg_param));

            const notificationEndpoint = Endpoints.NOTIFICATION_GET_DATA + applicantID;//"163354";//applicantID //"163354"
            let notify = {
                endpoint: notificationEndpoint,
                method: "GET",
            };
            dispatch(Actions.notificationInfo(notify));
        } else {
            navigate("/logout", { replace: true });
        }

        localStorage.removeItem("uploadScreen");

        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if ((submitedTask !== null && submitedTask !== undefined) || (taskList !== null && taskList !== undefined)) {
            
            const { DonorTasks } = taskList || submitedTask;
            if (typeof DonorTasks !== "undefined" && DonorTasks) {
                DonorTasks.sort(function (a, b) {
                    let c = new Date(dayMonthYearFormat(a.TaskDueDate));
                    let d = new Date(dayMonthYearFormat(b.TaskDueDate));
                    return c - d;
                });
                setDonorTasks(DonorTasks);
            }
        }
    }, [submitedTask, submittedTasks, taskList]);

    useEffect(() => {
        if (progressBarData !== null && progressBarData !== undefined) {
            if ((progressBarData?.ID === APPLICANT_REJECTED_STATUS.ID && progressBarData?.StatusText === APPLICANT_REJECTED_STATUS.StatusText)) {
                dispatch(LoginActions.loginReset());
                Logout(setCookie, removeCookie);
                navigate("/no-access", { replace: true });
            } else {
                const injectionCoach = document.getElementById('injectionCoach');
                if (injectionCoach && progressBarData?.ID === 6) {
                    injectionCoach.style.display = 'block';
                }
            }

            progressInfo.current = progressBarData;
            dispatch(Actions.progressBarClearData());
        }
        setScreenHeight();
    }, [progressBarData]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if ((notificationsList !== null && notificationsList !== undefined) || (notificationInfo !== null && notificationInfo !== undefined)) {
            let notifications = notificationsList || notificationInfo;

            if (notifications !== null && notifications !== undefined) {
                
                let taskNotifications = [];
                let notificationTaskLists =  notifications?.filter((notification) => ((notification.TaskStatus === 0 || notification.TaskStatus === 3) && notification.DonorNotificationTypeID === 1));
                
                if (donorTasks) {
                    
                    for (let i = 0; i < notificationTaskLists.length; i++) {                      

                        let validNotify = donorTasks?.filter((donorTask) => (donorTask.ID === notificationTaskLists[i].LinkedObjectID));
                        if (validNotify.length > 0) {
                            taskNotifications.push(notificationTaskLists[i]);
                        }
                    }
                }
                
                let notification = {
                    unreadMsgs: notifications?.filter((notification) => (notification.DonorNotificationTypeID === 2 && notification.IsAcknowledged === false)),
                    newTasks: taskNotifications?.filter((notification) => (notification.IsTaskOverdue === false && notification.IsAcknowledged === false)),
                    allTasks: taskNotifications?.filter((notification) => (notification.IsAcknowledged === false)),
                    tasks: taskNotifications?.filter((notification) => (notification.IsTaskOverdue === true)),
                    videos: notifications?.filter((notification) => (notification.DonorNotificationTypeID === 3 && notification.IsAcknowledged === false)),
                    calendar: notifications?.filter((notification) => (notification.DonorNotificationTypeID === 4 && notification.IsAcknowledged === false)),
                }
                
                if (notification) {
                    setNotificationContent(notification);
                }
            }
        }
        
    }, [notificationInfo, notificationsList, donorTasks]);

    useEffect(() => {
        if(iframeTask?.status === "done")
        {
            dispatch(Actions.iframeTasksStatusClear());
            navigate("/home/provide-info", {
                state: {
                    url:
                        process.env.REACT_APP_SITE_DOMAIN +
                        "/donors/application/?appTask=1&livdap_section-id="+iframeTask?.sectionID,
                },
            });

        }
    }, [iframeTask])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (taskId) {
            let submittedTasks = donorTasks.map((s) => {
                if (s.hasOwnProperty("ID")) {
                    if (s.ID === taskId) s.TaskStatus = 1;
                }
                return s;
            });
            // open the submit for approval section
            expandedItem.push("1");
            setExpandedItem(expandedItem);
            setDonorTasks(submittedTasks);
        }
    }, [approveTasks]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleAcknowledgeNotification = (taskId) => {

        let taskNotification = notificationContent?.allTasks.filter(notification => (notification?.LinkedObjectID === taskId));

        if(!isEmpty(taskNotification)){
            let notificationID = taskNotification[0]?.NotificationID;
            let params = {
                endpoint: Endpoints.NOTIFICATION_ACKNOWLEDGE + notificationID,
                method: "PUT",
            };
            dispatch(Actions.notificationAcknowledgeRequest(params));
        }
    }

    const handleModalSubmit = (taskid) => {
        setShowModel(false);
        setTaskId(taskid);
        if (taskid) {
            const taskcompleteEndpoint =
                Endpoints.HOME_COMPLETED_DONOR + taskid;
            let params = {
                endpoint: taskcompleteEndpoint,
                method: "PUT",
            };
            dispatch(Actions.sendToApproveRequest(params));
        }
    };

    const handleModalClose = () => {
        setShowModel(false);
    };

    /**
     * @func filter task based on type
     */
    const filterTasks = (filter) => donorTasks?.filter((task) => task.TaskStatus === filter);

    const getContentHeight = () => {
        const mainElement = document.getElementsByClassName("app");
        const headerElement = document.querySelector(".headerElement");
        const footerElement = document.querySelector(".footerElement");
        const pageTitle = document.querySelector(".homePageTitle");

        // 5px is additional gap added to prevent app container scroll
        return  mainElement[0]?.offsetHeight-(headerElement?.offsetHeight + footerElement?.offsetHeight + pageTitle?.offsetHeight + 5);
    }

    const setScreenHeight = () => {
        const debouncedHandleResize = debounce(function handleResize() {
            setScrollBarHeight(getContentHeight());
        }, 300);

        // set scroll height
        setScrollBarHeight(getContentHeight());
        window.addEventListener("resize", debouncedHandleResize);

        // cleanup listener
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize);
        }
    }

    useEffect(() => {
        setScreenHeight();
    }, [donorTasks, scrollbarHeight]);// eslint-disable-line react-hooks/exhaustive-deps

    const TaskHeader = (props) => {
        return (
            <li className={styles.taskHead}>
                <span className={styles.taskSmallName}>Task</span>
                <span className={styles.taskDueName}>Due</span>
                { props.taskStatus === 0 &&
                    <span className={styles.taskActionName}>Action</span>
                }
            </li>
        );
    };

    const isInThePast = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date < today;
    };

    const Task = ({ name, dueDate, type, subType, taskID, taskStatus, taskInfo }) => {
        const handleCompleteMode = (task, type) => {

            const msgTasks = donorTasks.filter((tasks) => {
                return tasks.ID === task;
            });

            dispatch(Actions.sendToApproveClear()); // to clear the response before moving to the respective screen
            
            if (type === TASK_TYPE.DIRECT_LINK) {

                /**
                 * 2 = Family profile
                 * 3 = Essays
                 * 1 = Personal Profile
                 */

                // direct link
                let section_id = 2;
                if(taskInfo.SubTaskType === 1)
                {
                    section_id = 5;
                }
                else if(taskInfo.SubTaskType === 2)
                {
                    section_id = 6;
                }
                else if(taskInfo.SubTaskType === 3)
                {
                    section_id = 7;
                }

                // if the task is resubmit or redo then update the status in the wordpress,
                // then update the task application to active in wp

                navigate("/home/provide-info", {
                    state: {
                        url:
                            process.env.REACT_APP_SITE_DOMAIN +
                            "/donors/application/?appTask=1&livdap_section-id=" + section_id,
                        pageTitle: taskInfo.TaskName
                    },
                });
            }
            if (type === TASK_TYPE.AD_HOC) {
                // show modal
                if (msgTasks) {
                    let modelContent = [];
                    modelContent.taskID = msgTasks[0].ID;
                    modelContent.title = msgTasks[0].TaskName;
                    modelContent.message = msgTasks[0].AdHocDescription;
                    modelContent.callback = "modelYes";
                    setModelInfo(modelContent);
                    setShowModel(true);
                }
            } else if (type === TASK_TYPE.ADDITIONAL_INFO ||
                (type === TASK_TYPE.MISC && subType === 3)) {
                navigate("/home/additional-info", {
                    state: {
                        id: taskID,
                        taskname: msgTasks[0].TaskName,
                        message: msgTasks[0].AdHocDescription,
                        taskInfo: msgTasks[0]
                    },
                });
            }  else if (type === TASK_TYPE.AUSTRALIAN_PROGRAM && subType === 1) {
                navigate("/home/australian-messaging", {
                    state: {
                        id: taskID,
                        taskname: msgTasks[0].TaskName,
                        message: msgTasks[0].AdHocDescription,
                        taskInfo: msgTasks[0]
                    },
                });
            }else if (type === TASK_TYPE.SCHEDULE && ([1, 9].includes(taskInfo.SubTaskType))) { // subtype = 1- acuity, 9- audio acuity
                navigate("/acuity", { ///home/schedule
                    state: {
                        id: taskID,
                        page: "acuity",
                        taskname: msgTasks[0].TaskName,
                        message: msgTasks[0].Comment,
                        taskInfo: msgTasks[0]
                    },
                });
            } else if (type === TASK_TYPE.LAB && ([2, 3].includes(taskInfo.SubTaskType))) {
                navigate("/home/testing-kit", {
                    state: {
                        id: taskID,
                        taskInfo: taskInfo
                    },
                });
            } else if (type === TASK_TYPE.LAB && (taskInfo.SubTaskType === 1 || taskInfo.SubTaskType === 4)) {
                navigate("/home/complete-lab", {
                    state: {
                        id: taskID,
                        pageTitle: (taskInfo.SubTaskType === 1 ? "Schedule Lab Work" : "Complete Infectious Disease Testing"),
                        taskInfo: taskInfo
                    },
                });
            } else if (type === TASK_TYPE.SCHEDULE && taskInfo.SubTaskType === 3) {
                navigate("/home/task-schedule", { //subtype = 3- Photo shoot
                    state: {
                        id: taskID,
                        page: "Schedule",
                        taskname: "Schedule Photo Shoot",
                        taskInfo: msgTasks[0],
                    },
                });
            } else if (type === TASK_TYPE.SCHEDULE && ([4, 5, 8].includes(taskInfo.SubTaskType))) { // subtype = 3- Photo shoot
                navigate("/home/schedule", { ///home/schedule
                    state: {
                        id: taskID,
                        page: "Schedule",
                        taskname: msgTasks[0].TaskName,
                        message: msgTasks[0].Comment,
                        taskInfo: msgTasks[0]
                    },
                });
            } else if (type === TASK_TYPE.UPLOAD) {
                msgTasks[0].minUpload = 0;
                msgTasks[0].maxUpload = 0;
                if (taskInfo.SubTaskType === 5) {
                    msgTasks[0].minUpload = 4;
                    msgTasks[0].maxUpload = 5;
                }
                if (taskInfo.SubTaskType === 6) {
                    msgTasks[0].minUpload = 6;
                    msgTasks[0].maxUpload = 10;
                }
                navigate("/home/upload", {
                    state: {
                        id: taskID,
                        page: "upload",
                        taskname: msgTasks[0].TaskName,
                        message: msgTasks[0].Comment,
                        taskInfo: msgTasks[0]
                    },
                });
            } else if (type === TASK_TYPE.APPOINTMENT) {
                navigate("/appointment", {
                    state: {
                        id: taskID,
                        taskInfo: msgTasks[0]
                    },
                });
            } else if (type === TASK_TYPE.SCHEDULE && ([6, 7].includes(taskInfo.SubTaskType))) {
                let url = (taskInfo.SubTaskType === 6 ? "/home/psychological-evaluation" : "/home/pcp-obgyn");
                navigate(url, {
                    state: {
                        id: taskID,
                        taskname: msgTasks[0]?.TaskName,
                        taskInfo: msgTasks[0]
                    }
                });
            } else if (type === TASK_TYPE.INFO_VIDEO && taskInfo?.SubTaskType === 1) {
                navigate("/home/video-session", {
                    state: {
                        taskInfo: msgTasks[0]
                    },
                });
            } else if (type === TASK_TYPE.MISC && taskInfo?.SubTaskType === 4) {
                navigate("/home/notify-clinic", {
                    state: {
                        taskInfo: msgTasks[0]
                    },
                });
            } else if (type === TASK_TYPE.DOCU_SIGN && taskInfo?.SubTaskType === 1) {
                navigate("/home/docu-sign", {
                    state: {
                        taskInfo: msgTasks[0]
                    },
                });
            } else if (type === TASK_TYPE.TRAVEL_DONOR && taskInfo?.SubTaskType === 1) {
                navigate("/travel-form", {
                    state: {
                        taskInfo: msgTasks[0]
                    },
                });
            } else if (type === TASK_TYPE.TRAVEL_DONOR && taskInfo?.SubTaskType === 2) {
                navigate("/travel-iternary", {
                    state: {
                        taskInfo: msgTasks[0]
                    },
                });
            } else if (type === TASK_TYPE.TRAVEL_HANDOUT && taskInfo?.SubTaskType === 1) {
                navigate("/home/travel-program-handout", {
                    state: {
                        id: taskID,
                        taskname: msgTasks[0].TaskName,
                        message: msgTasks[0].AdHocDescription,
                        taskInfo: msgTasks[0]
                    },
                });
            } else if (type === TASK_TYPE.DONOR_INFO_BROCHURE && taskInfo?.SubTaskType === 1) {
                navigate("/home/donor-info-brochure", {
                    state: {
                        taskInfo: msgTasks[0]
                    },
                });
            }

            handleAcknowledgeNotification(taskID);
        };


        let taskNotify = (notificationContent?.allTasks ? notificationContent?.allTasks.filter(notification => (notification?.LinkedObjectID === taskID)) : []);

        // condition variables
        const taskIsNew = taskNotify?.length > 0;
        const taskIsImportant = taskInfo.IsImportant === true;
        const taskIsReSubmitted = taskInfo.IsRejected === true;
        const taskIsOverdue = isInThePast(new Date(monthDayYearFormat(dueDate)));

        const TaskSymbol = () => {
            let impSymbol = <span className={`${styles.impClasses} text-center text-danger`}>!</span>;
            let dotSymbol = <span className={`${styles.dotClasses} flex-shrink-0 rounded-circle`}></span>;

            if(taskIsOverdue || taskIsImportant) {
                return impSymbol;
            }
            if(taskIsNew || taskIsReSubmitted){
                return dotSymbol;
            }
        }

        return (
            <>
                {taskStatus === 0 ? (
                    <li
                        className={`mt-3 mb-3 cursor-pointer ${styles.mainTasks}
                            ${taskIsNew ? styles.taskNew : ""}
                            ${taskIsOverdue ? styles.taskOverdue : ""}
                            ${taskIsReSubmitted ? styles.taskReSubmitted : ""}
                            ${taskIsImportant ? styles.taskImportant : ""}
                        `}
                        onClick={() => handleCompleteMode(taskID, type)}
                        data-id={taskInfo.ID}
                    >
                        {" "}
                        <div className={styles.task}>
                            <TaskSymbol />
                            <span className={styles.taskSmallName}>
                                {name}
                            </span>
                            <span className={styles.taskDueName}>
                                {dayjs(dueDate, "MM-DD-YYYY").format("MM/DD/YYYY")}
                            </span>
                            <span className={styles.taskActionName}>
                                <TaskRightIcon />
                            </span>
                        </div>
                    </li>
                ) : (
                    <li className={`mt-3 mb-3 ${styles.mainTasks} `}>
                        {" "}
                        <div className={styles.task}>
                            <span className={styles.taskSmallName}>{name}</span>
                            <span className={styles.taskDueName}>
                                {dayjs(dueDate, "MM-DD-YYYY").format("MM/DD/YYYY")}
                            </span>
                        </div>
                    </li>
                )}
            </>
        );
    };

    const TaskList = (props) => {
        let filteredTasks = filterTasks(props.status);
        
        if(props.status === 0)
        {
            if(filteredTasks !== undefined)
            {
                let rejectedTasks = filterTasks(TASK_STATUS.REJECTED);
                filteredTasks = [...filteredTasks,...rejectedTasks];

                filteredTasks = filteredTasks.sort(function (a, b) {
                    let c = new Date(dayMonthYearFormat(a.TaskDueDate));
                    let d = new Date(dayMonthYearFormat(b.TaskDueDate));
                    return c - d;
                });
            }
        }

        return (
            <>
                {filteredTasks?.length > 0 ? (
                    <ul
                        className={styles.taskLists}
                        style={{
                            transition:
                                "opacity 1s ease-in-out,height 0.25s ease-in",
                        }}
                    >
                        <TaskHeader taskStatus={props.status}/>
                        {filteredTasks.map((task) => (
                            <Task
                                name={task.TaskName}
                                dueDate={task.TaskDueDate}
                                key={task.ID}
                                type={task.TaskType}
                                subType={task.SubTaskType}
                                taskID={task.ID}
                                taskStatus={props.status}
                                taskInfo={task}
                            />
                        ))}
                    </ul>
                ) : (
                    <Alert variant="danger" className="text-center">
                        No Tasks
                    </Alert>
                )}
            </>
        );
    };
    return (
        <Layout classes={"d-flex flex-column"} dueTasksCounter={notificationContent}>
            <BackgroundFetch requestBy={['tasks', 'notify']} />
            {
                (loading || appovalLoading || loginLoading || iframeTasksLoading) && domReady ? createPortal( <Loader />,
                document.querySelector('.app')): null
            }
            {showModel && (
                <>
                    <PopModal
                        taskID={modelInfo.taskID}
                        title={modelInfo.title}
                        message={modelInfo.message}
                        closeButtonAction={handleModalClose}
                        callbackContinue={handleModalSubmit}
                    />
                </>
            )}
            <div className={styles.popupOverlay}/>
            <div className={`${styles.pageTitleSection} homePageTitle`}>
                {
                    (progressInfo?.current?.ID) &&
                    <>
                        <h1 className="fw-normal text-white text-center">
                            {progressInfo?.current?.StatusText}
                        </h1>
                        {
                            (progressInfo?.current?.ID !== 5 && progressInfo?.current?.ID !== 6 && progressInfo?.current?.ID !== 7) ?
                                <HeaderProgress progressData={progressInfo?.current} />
                                :
                                <p></p>
                        }
                        
                    </>
                }
            </div>
            <div className="wrapper px-0 py-0 flex-grow-1 d-flex flex-column">
                <div
                    id="taskWrapper"
                    className={`${styles.taskWrapper} flex-grow-1 mt-0`}
                    ref={listRef}
                >
                    <Scrollbars
                        // onScrollStop={scrollStopMsgContainer}
                        autoHeight
                        autoHeightMax={scrollbarHeight}
                        ref={containerRef}
                        className="scroll-container"
                    >
                        <Accordion
                            style={{ height: height }}
                            defaultActiveKey={expandedItem}
                            alwaysOpen
                            className="pb-1 px-3"
                            ref={containerRef}
                        >
                            <Accordion.Item
                                className="border-bottom taskItem1"
                                eventKey="0"
                            >
                                <Accordion.Header>
                                    <span className={styles.taskName}>
                                        To Do
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <TaskList status={TASK_STATUS.TODO} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                className="border-bottom taskItem2"
                                eventKey="1"
                            >
                                <Accordion.Header>
                                    <span className={styles.taskName}>
                                        Submitted for Approval
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <TaskList
                                        status={TASK_STATUS.NEED_APPROVAL}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item
                                className="border-bottom taskItem3"
                                eventKey="2"
                            >
                                <Accordion.Header>
                                    <span className={styles.taskName}>
                                        Completed
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <TaskList status={TASK_STATUS.COMPLETE} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Scrollbars>
                </div>
            </div>
        </Layout>
    );
}
export default Home;
